<template>
  <div>
    <v-btn @click="toggleFilterOnXs()" class="search-bar-toggle d-md-none"
      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
    >
    <v-sheet
      :class="['books-filters-parent', showFilterOnXs ? 'show' : '']"
      outlined
    >
      <div
        :class="['px-6 pt-2 d-flex justify-end align-center mb-2']"
        style="height: 30px"
      >
        <v-checkbox
          v-if="!mlState.libraryParent"
          v-model="toSubLibrary"
          dense
          label="Distribute to Sub Library"
          class="mt-3 mr-2 caption"
        >
        </v-checkbox>
        <v-dialog v-if="selectedBooks.length > 0" max-width="950">
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              left
              :content="selectedBooks.length"
              overlap
              class="float-right"
            >
              <v-btn
                tile
                x-small
                color="primary"
                class="px-4 ml-2"
                v-bind="attrs"
                v-on="on"
              >
                Selected Books
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >Selected books to distribute</v-toolbar
              >
              <smooth-scrollbar>
                <div class="selectedBooksRow">
                  <v-row class="pt-6 px-2 mx-0">
                    <BookCard
                      v-for="book in selectedBooksDetails"
                      :key="book._id"
                      :book="book"
                      type="distribute"
                      sm="6"
                      xs="12"
                      md="4"
                      lg="4"
                      cols="12"
                    />
                  </v-row>
                </div>
              </smooth-scrollbar>
              <v-card-actions class="justify-end pb-4">
                <v-btn tile color="primary" @click="resetSelectedBooks()"
                  >Clear all</v-btn
                >
                <v-btn tile color="primary" @click="dialog.value = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-btn tile x-small @click="resetFilter()" class="ml-2">Reset</v-btn>
        <v-btn tile x-small @click="toggleFilterOnXs()" class="ml-2 d-md-none"
          >Hide</v-btn
        >
      </div>
      <div :class="['d-flex', 'align-center']">
        <v-row class="mx-0 px-4 pt-2">
          <v-col
            :xl="toSubLibrary ? 10 : 7"
            :md="toSubLibrary ? 10 : 7"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-autocomplete
              v-if="!toSubLibrary"
              v-model="member"
              clearable
              :items="membersData"
              :loading="isMemberLoading"
              :search-input.sync="memberSearchKey"
              :filter="filterMembers"
              item-text="name"
              item-value="_id"
              :placeholder="
                distributionType === 'Balavedhi'
                  ? 'Select a member to distribute books'
                  : ''
              "
              class="mx-2 mt-0"
              dense
            >
              <template v-slot:label>
                <span
                  v-if="distributionType === 'Balavedhi'"
                  class="warning--text"
                  >Listed members: under 18 only.</span
                >
                <span v-else>Select a member to distribute books</span>
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="`# ${data.item.memberNumber} ${data.item.name}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-select
              v-else
              dense
              class="mx-2 mt-0"
              v-model="subLibrary"
              :loading="!isSubLibrariesLoaded"
              :items="subLibraries"
              item-text="name"
              item-value="_id"
              label="Select a sub library to distribute books"
              clearable
            ></v-select>
          </v-col>

          <v-col
            v-if="!toSubLibrary"
            xl="3"
            md="3"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2 mt-0"
              v-model="distributionType"
              :items="['General', 'Balavedhi', 'Reference', 'E-Reader']"
              label="Distribution Type"
            ></v-select>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <div class="mx-2">
              <v-btn
                @click="openConfirmBox"
                color="success"
                width="100%"
                small
                tile
                :disabled="
                  (!selectedmember && !toSubLibrary) ||
                  (!selectedsubLibrary && toSubLibrary) ||
                  selectedBooks.length === 0
                "
              >
                Distribute
              </v-btn>
              <v-dialog max-width="600" v-model="showDialog">
                <v-card
                  v-if="
                    selectedBooks.length > 0 &&
                    (selectedmember || selectedsubLibrary)
                  "
                >
                  <v-form
                    ref="confirmForm"
                    v-model="confirmFormValid"
                    lazy-validation
                    @submit.prevent="
                      initiateDistribute(() => (showDialog = false))
                    "
                  >
                    <v-toolbar v-if="!toSubLibrary" dense color="primary" dark
                      >Confirm distribution?</v-toolbar
                    >
                    <v-toolbar v-else dense color="warning" dark
                      >This distribution is done to a Sub Library</v-toolbar
                    >
                    <div
                      v-if="!toSubLibrary && selectedmember"
                      class="text--primary pt-6 px-4"
                    >
                      Attention <b>{{ selectedmember.name }}</b> the following
                      book{{ selectedBooks.length > 1 ? "s" : "" }}
                      is going to be distributed to you, please confim by
                      entering your sign passcode!
                      <v-text-field
                        v-model="memberSignPasscode"
                        dense
                        maxlength="4"
                        class="mt-6 my-0"
                        label="Sign Passcode"
                        type="password"
                        name="____"
                        :rules="[
                          (v) => v.length === 4 || 'Enter a valid passcode'
                        ]"
                      ></v-text-field>
                    </div>
                    <div
                      v-else-if="selectedsubLibrary"
                      class="text--primary pt-6 px-4"
                    >
                      Are you sure to distribute the following book{{
                        selectedBooks.length > 1 ? "s" : ""
                      }}
                      to sub library
                      <b>{{ selectedsubLibrary.name }}</b>
                    </div>
                    <div class="px-4 pt-2 pb-4">
                      <v-menu
                        ref="distributeOnMenu"
                        v-model="distributeOnMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="distributeDateText"
                            class="mt-6 my-0"
                            label="Distribution Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :max="purchaseFilterMaxDate"
                          no-title
                          v-model="distributeDate"
                          min="1950-01-01"
                          scrollable
                          @change="$refs.distributeOnMenu.save(distributeDate)"
                        ></v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="distributeOnTimeMenu"
                        v-model="distributeOnTimeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="distributeTimeText"
                            class="mt-6 my-0"
                            label="Distribution Time"
                            append-icon="mdi-clock-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          no-title
                          v-model="distributeTime"
                          scrollable
                          format="ampm"
                          landscape
                          @change="
                            $refs.distributeOnTimeMenu.save(distributeTime)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <smooth-scrollbar>
                      <div style="max-height: 180px">
                        <v-row no-gutters class="px-4">
                          <div>
                            <v-chip
                              v-for="book in selectedBooksDetails"
                              :key="book._id"
                              class="mr-1 mb-2"
                            >
                              {{ book.name }}
                            </v-chip>
                          </div>
                        </v-row>
                      </div>
                    </smooth-scrollbar>
                    <v-card-actions class="justify-end pb-4">
                      <v-btn
                        small
                        tile
                        color="primary"
                        @click="showDialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn type="submit" small tile color="success"
                        >Continue</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </div>
      <div :class="['d-flex', 'align-center']">
        <v-row class="mx-0 px-4">
          <v-col xl="5" md="5" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-text-field
              v-model="searchKey"
              ref="searchInput"
              clearable
              dense
              class="mx-2"
              append-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
          </v-col>

          <v-col xl="3" md="3" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              :items="searchByFilters"
              item-text="name"
              item-value="_id"
              v-model="searchBy"
              label="Search By"
              clearable
            ></v-select>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <vc-data-autocomplete
              :disabled="
                distributionType === 'E-Reader' ||
                distributionType === 'Reference'
              "
              v-model="type"
              api="/book/type"
              map-value="name"
              emit-on-search
              clearable
              class="mx-2"
              dense
              label="Category"
            />
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              v-model="orderBy"
              :items="searchByOrder"
              item-text="name"
              item-value="orderBy"
              label="Order by"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { useDistributeFilter } from "@/modules";
import BookCard from "@/components/BookCard";

export default {
  setup: useDistributeFilter,
  props: ["loadNextPage"],
  components: {
    BookCard
  }
};
</script>
